<template>
  <b-card>
    <b-row>
      <!-- new password -->
      <b-col md="6">
        <b-form-group
          label-for="user-new-password"
          :label="$t('Password')"
        >

          <validation-provider
            #default="validationContext"
            :name="$t('Password')"
            rules="required"
            vid="newpassword"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="user-new-password"
                ref="new_password"
                v-model="passwordData.new_password"
                :type="passwordFieldTypeNew"
                name="new-password"
                :placeholder="$t('New Password')"
                :state="getValidationState(validationContext)"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <!--/ new password -->

      <!-- retype password -->
      <b-col md="6">
        <b-form-group
          label-for="user-retype-new-password"
          :label="$t('Retype Password')"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Retype Password')"
            rules="required|confirmed:newpassword"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="user-retype-password"
                v-model="passwordData.retype_password"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                :placeholder="$t('Retype Password')"
                :state="getValidationState(validationContext)"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <!--/ retype password -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider, localize,
} from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    passwordData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    localize(this.$i18n.locale)
    this.$refs.new_password.focus()
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
