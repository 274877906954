<template>
  <div>
    <b-row>
      <!-- email -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Email')"
          label-for="account-email"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Email')"
            rules="required|email"
          >
            <b-form-input
              id="account-email"
              ref="account_email"
              v-model="generalData.email"
              :placeholder="$t('Email')"
              :state="getValidationState(validationContext)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>

        </b-form-group>
      </b-col>

      <!-- username -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Username')"
          label-for="account-username"
        >

          <validation-provider
            #default="validationContext"
            :name="$t('Username')"
            rules="required"
          >
            <b-form-input
              id="account-username"
              v-model="generalData.username"
              :placeholder="$t('Username')"
              :state="getValidationState(validationContext)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- name -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Full Name')"
          label-for="account-name"
        >
          <b-form-input
            v-model="generalData.name"
            name="name"
            :placeholder="$t('Name')"
          />
        </b-form-group>
      </b-col>

      <!-- company -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Company')"
          label-for="account-company"
        >
          <b-form-input
            v-model="generalData.company"
            name="company"
            :placeholder="$t('Company')"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- role -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          :label="$t('User Role')"
          label-for="user-role"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('User Role')"
            rules="required"
          >
            <v-select
              v-model="generalData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
              :state="getValidationState(validationContext)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- merchant -->
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group
          :label="$t('Merchant')"
          label-for="user-merchant"
        >
          <v-select
            v-model="generalData.merchant_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="generalData.merchant_options"
            :reduce="val => val.value"
            :clearable="true"
            input-id="user-merchant"
            :disabled="generalData.role !== 'merchant'"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- status -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('Status')"
          label-for="user-status"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Status')"
            rules="required"
          >
            <v-select
              v-model="generalData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
              :state="getValidationState(validationContext)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- pricing-plan -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('Pricing Plan')"
          label-for="user-pricing-plan"
        >
          <v-select
            v-model="generalData.pricing_plan_code"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="generalData.pricing_plan_options"
            :reduce="val => val.value"
            :clearable="true"
            input-id="user-pricing-plan"
          />
        </b-form-group>
      </b-col>

      <!-- is_verified -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          :label="$t('Email Verify')"
          label-for="user-verified"
        >
          <b-form-checkbox
            id="user-verified"
            v-model="generalData.is_verified"
            name="check-button"
            switch
            inline
          >
            <span>{{ (generalData.is_verified === true)? $t('Is Verified') : $t('Not Verify') }}</span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { t } from '@/@core/libs/i18n/utils'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
    }
  },
  mounted() {
    this.$refs.account_email.focus()
  },
  methods: {
  },
  setup(props) {
    let roleOptions = [
      { label: t('Admin'), value: 'admin' },
      { label: t('Staff'), value: 'staff' },
      { label: t('Subscriber'), value: 'subscriber' },
      { label: t('Merchant'), value: 'merchant' },
    ]
    if (props.generalData.role_options !== undefined) {
      roleOptions = props.generalData.role_options
    }

    let statusOptions = [
      { label: t('Pending'), value: 'pending' },
      { label: t('Active'), value: 'active' },
      { label: t('Inactive'), value: 'inactive' },
    ]
    if (props.generalData.status_options !== undefined) {
      statusOptions = props.generalData.status_options
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      roleOptions,
      statusOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
