<template>
  <b-card>

    <!-- Header: Personal Info -->
    <div class="d-flex mb-1">
      <feather-icon
        icon="InfoIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Personal Information') }}
      </h4>
    </div>

    <b-row class="mt-1">
      <!-- bio -->
      <b-col cols="12">
        <b-form-group
          :label="$t('Bio')"
          label-for="account-bio"
        >
          <b-form-textarea
            id="account-bio"
            v-model="informationData.bio"
            rows="3"
            :placeholder="$t('Your bio data here')"
          />
        </b-form-group>
      </b-col>
      <!--/ bio -->

      <!-- birth date -->
      <b-col md="6">
        <b-form-group
          label-for="account-dob"
          :label="$t('Birth date')"
        >
          <flat-pickr
            id="account-dob"
            v-model="informationData.dob"
            :config="{ dateFormat: 'd F Y' }"
            class="form-control"
            :placeholder="$t('Birth date')"
          />
        </b-form-group>
      </b-col>
      <!--/ birth date -->

      <!-- Country -->
      <b-col md="6">
        <b-form-group
          label-for="account-country"
          :label="$t('Country')"
        >
          <v-select
            id="account-country"
            v-model="informationData.country"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="countryOption"
          />
        </b-form-group>
      </b-col>
      <!--/ Country -->

      <!-- website -->
      <b-col md="6">
        <b-form-group
          label-for="account-website"
          :label="$t('Website')"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Website')"
          >
            <b-form-input
              id="account-website"
              v-model="informationData.website"
              :placeholder="$t('Website')"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

        </b-form-group>
      </b-col>
      <!--/ website -->

      <!-- phone -->
      <b-col md="6">
        <b-form-group
          label-for="account-mobile"
          :label="$t('Mobile')"
        >
          <cleave
            id="account-mobile"
            v-model="informationData.mobile"
            class="form-control"
            :raw="false"
            :options="clevePhone"
            :placeholder="$t('Mobile number')"
          />
        </b-form-group>
      </b-col>
      <!-- phone -->

      <!-- Language -->
      <b-col md="6">
        <b-form-group
          :label="$t('Language')"
          label-for="account-language"
        >
          <v-select
            id="account-language"
            v-model="informationData.language"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="languageOptions"
            :reduce="val => val.value"
            :clearable="false"
            input-id="language"
          />
        </b-form-group>
      </b-col>
      <!--/ Language -->

      <!-- Gender -->
      <b-col md="6">
        <b-form-group
          :label="$t('Gender')"
          label-for="gender"
          label-class="mb-1"
        >

          <validation-provider
            #default="{ errors }"
            :name="$t('Gender')"
            rules="required"
          >
            <b-form-radio-group
              id="gender"
              v-model="informationData.gender"
              :options="genderOptions"
              value="male"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!--/ Gender -->

    </b-row>

    <!-- Header: Location -->
    <div class="d-flex mt-2 mb-1">
      <feather-icon
        icon="MapPinIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Location') }}
      </h4>
    </div>

    <b-row class="mt-1">

      <!-- Field: Address Line 1 -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="`${$t('Address Line')} 1`"
          label-for="location-address-line-1"
        >
          <b-form-input
            id="location-address-line-1"
            v-model="informationData.location.add_line_1"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Address Line 2 -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="`${$t('Address Line')} 2`"
          label-for="address-line-2"
        >
          <b-form-input
            id="address-line-2"
            v-model="informationData.location.add_line_2"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <!-- Field: Postcode -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="$t('Postcode')"
          label-for="location-postcode"
        >
          <b-form-input
            id="location-postcode"
            v-model="informationData.location.post_code"
            type="number"
            :placeholder="$t('Postcode')"
          />
        </b-form-group>
      </b-col>

      <!-- Field: City -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="`${$t('City')}/${$t('District')}`"
          label-for="location-city"
        >
          <b-form-input
            id="location-city"
            v-model="informationData.location.city"
            :placeholder="`${$t('City')}/${$t('District')}`"
          />
        </b-form-group>
      </b-col>

      <!-- Field: State -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="`${$t('State')}/${$t('Province')}`"
          label-for="location-state"
        >
          <b-form-input
            id="location-state"
            v-model="informationData.location.state"
            :placeholder="`${$t('State')}/${$t('Province')}`"
          />
        </b-form-group>
      </b-col>

      <!-- Field: Country -->
      <b-col
        cols="12"
        md="6"
        lg="4"
      >
        <b-form-group
          :label="$t('Country')"
          label-for="location-country"
        >
          <v-select
            id="location-country"
            v-model="informationData.location.country"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="countryOption"
            :placeholder="$t('Country')"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
  BFormRadioGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { t } from '@/@core/libs/i18n/utils'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BFormInvalidFeedback,
    BFormRadioGroup,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['Thailand'],
      userData: JSON.parse(localStorage.getItem('userData')),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'TH',
      },
      required,
    }
  },
  setup() {
    const languageOptions = [
      { label: t('English'), value: 'english' },
      { label: t('Thai'), value: 'thai' },
    ]

    const genderOptions = [
      { text: t('Male'), value: 'male' },
      { text: t('Female'), value: 'female' },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      languageOptions,
      genderOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
